// Entry point for the build script in your package.json

// Rails
import Rails from "@rails/ujs";
Rails.start();

// React
import { FC } from "react";
import * as Components from "./components";
import ReactRailsUJS from "react_ujs";
ReactRailsUJS.getConstructor = (className: string) =>
  Components[className] as FC;

import readyHandler from "./lib/readyHandler";

import dropCap from "./features/dropCap";
import expandableSubnav from "./features/expandableSubnav";
import fileDrop from "./features/fileDrop";
import forms from "./features/forms";
import mobileNav from "./features/mobileNav";
import randomBackground from "./features/randomBackground";
import represent from "./features/represent";
import richText from "./features/richText";
import sortableList from "./features/sortableList";
import toast from "./features/toast";
import vibrantColors from "./features/vibrantColors";
import welcomeNotice from "./features/welcomeNotice";

readyHandler.ready(() => {
  dropCap();
  expandableSubnav();
  fileDrop();
  forms();
  mobileNav();
  randomBackground();
  represent();
  richText();
  sortableList();
  toast();
  vibrantColors();
  welcomeNotice();
});
